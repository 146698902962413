import {
  customSessionClient,
  emailOTPClient,
  passkeyClient,
} from 'better-auth/client/plugins'
import { createAuthClient } from 'better-auth/react'
import type { auth } from './auth.ts'

export const authClient = createAuthClient({
  plugins: [
    customSessionClient<typeof auth>(),
    emailOTPClient(),
    passkeyClient(),
  ],
})

export const { signIn, signOut, useSession, getSession } = authClient
