import { createContext, useEffect, useState } from 'react'
import { getSession } from '../authClient.ts'
import type { Session } from './useSession.ts'

export const SessionContext = createContext<{
  session: Session | null
  refetchSession: () => Promise<void>
  updateSession: (session: Session) => void
}>({
  session: null,
  refetchSession: async () => {},
  updateSession: () => {},
})

export default function useLayoutSessionContext(serverSession: Session | null) {
  const [session, setSesssion] = useState<Session | null>(serverSession)

  const refetchSession = async () => {
    const session = (await getSession()).data as unknown as Session
    if (session) {
      setSesssion(serializeSession(session))
    }
  }

  const updateSession = (newSession: Session) => {
    setSesssion(serializeSession(newSession))
  }

  useEffect(() => {
    refetchSession()
  }, [])

  return {
    refetchSession,
    updateSession,
    session,
  }
}

function serializeSession(session: Session): Session {
  return {
    ...session,
    user: {
      ...session.user,
      updatedAt: new Date(session.user.updatedAt),
      createdAt: new Date(session.user.createdAt),
      emailVerified: session.user.emailVerified,
      memberships: session.user.memberships.map((membership) => ({
        ...membership,
        createdAt: new Date(membership.createdAt),
        updatedAt: new Date(membership.updatedAt),
        invitedAt: membership.invitedAt ? new Date(membership.invitedAt) : null,
        company: {
          ...membership.company,
          createdAt: new Date(membership.company.createdAt),
          updatedAt: new Date(membership.company.updatedAt),
          taskEmailLastSentAt: membership.company.taskEmailLastSentAt
            ? new Date(membership.company.taskEmailLastSentAt)
            : null,
        },
      })),
    },
    company: {
      ...session.company,
      createdAt: new Date(session.company.createdAt),
      updatedAt: new Date(session.company.updatedAt),
      taskEmailLastSentAt: session.company.taskEmailLastSentAt
        ? new Date(session.company.taskEmailLastSentAt)
        : null,
    },
  }
}
