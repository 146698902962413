'use client'

import useLayoutSessionContext, {
  SessionContext,
} from '@/utils/auth/useLayoutSessionContext.ts'
import type { Session } from '@/utils/auth/useSession.ts'
import type { ReactNode } from 'react'

type Props = {
  serverSession: Session | null
  children: ReactNode
}

export function SessionProvider({ serverSession, children }: Props) {
  const { session, updateSession, refetchSession } =
    useLayoutSessionContext(serverSession)

  return (
    <SessionContext.Provider
      value={{
        session,
        updateSession,
        refetchSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}
